/**
 * Copyright 2023 Eugene Khyst
 * SPDX-License-Identifier: Apache-2.0
 */

import {Button, Card, Flex, Grid, Skeleton, theme, Typography} from 'antd';
import type {ReactNode} from 'react';
import {useContext, useEffect, useState} from 'react';
import reactStringReplace from 'react-string-replace';

import {apiUrl} from '~/src/config';
import {TabContext} from '~/src/contexts/TabContext';
import {useAds} from '~/src/hooks';
import type {AdDefinition} from '~/src/services/ads';
import {useAppStore} from '~/src/stores/app-store';
import {TabKey} from '~/src/tabs';
import {randomInt} from '~/src/utils';

const DEFAULT_PLACEMENT = 'all';
const AD_CHANGE_INTERVAL = 10 * 1000;
const IMAGE_SIZE = [200, 200];

function getImageUrl({image}: AdDefinition, adsUrl: string): string {
  return new URL(image, adsUrl).toString();
}

function formatRichText(text: string): ReactNode[] {
  let i = 1;
  let replacedText: ReactNode[];
  replacedText = reactStringReplace(text, '<br/>', () => <br key={i++} />);
  replacedText = reactStringReplace(replacedText, /<strong>(.+?)<\/strong>/g, match => (
    <Typography.Text strong key={i++}>
      {match}
    </Typography.Text>
  ));
  replacedText = reactStringReplace(replacedText, /<code>(.+?)<\/code>/g, match => (
    <Typography.Text code key={i++}>
      {match}
    </Typography.Text>
  ));
  return replacedText;
}

type Props = {
  vertical?: boolean;
};

export const Ad: React.FC<Props> = ({vertical = false}: Props) => {
  const tab: TabKey = useContext(TabContext);
  const setActiveTabKey = useAppStore(state => state.setActiveTabKey);

  const {
    token: {colorFillSecondary},
  } = theme.useToken();
  const screens = Grid.useBreakpoint();

  const {ads: {ads, placements} = {ads: {}, placements: {}}, isLoading} = useAds();

  const [adIndex, setAdIndex] = useState<number>(randomInt(0, 9));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setAdIndex((prev: number) => prev + 1);
    }, AD_CHANGE_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  const adKeys: string[] = placements[tab] ?? placements[DEFAULT_PLACEMENT] ?? [];
  const adKey: string | undefined = adKeys[adIndex % adKeys.length];
  const ad: AdDefinition | undefined = ads[adKey];
  if (!isLoading && !ad) {
    return <></>;
  }
  const [width, height] = IMAGE_SIZE;
  return (
    <Card
      hoverable
      style={{backgroundColor: colorFillSecondary}}
      styles={{body: {padding: 0, overflow: 'hidden'}}}
    >
      <Flex vertical={vertical || !screens['md']} align="center">
        {isLoading ? (
          <Skeleton.Image active style={{width, height, objectFit: 'contain'}} />
        ) : (
          <>
            <img
              src={getImageUrl(ad, apiUrl)}
              style={{
                display: 'block',
                width,
                height,
                objectFit: 'contain',
              }}
            />
          </>
        )}
        <Flex vertical align="flex-start" style={{padding: 16}}>
          {isLoading ? (
            <>
              <Skeleton active title={false} paragraph={{rows: 3}} />
              <Skeleton.Button active />
            </>
          ) : (
            <>
              <div style={{marginBottom: 16, textAlign: 'justify'}}>{formatRichText(ad.text)}</div>
              {ad.linkUrl && (
                <Button type="primary" size="large" href={ad.linkUrl} target="_blank">
                  {ad.linkText}
                </Button>
              )}
              {ad.linkTab && Object.values(TabKey).includes(ad.linkTab as TabKey) && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => void setActiveTabKey(ad.linkTab as TabKey)}
                >
                  {ad.linkText}
                </Button>
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};
