import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import * as React from 'react';
import CascaderContext from "../context";

/**
 * Control the active open options path.
 */
var useActive = function useActive(multiple, open) {
  var _React$useContext = React.useContext(CascaderContext),
    values = _React$useContext.values;
  var firstValueCells = values[0];

  // Record current dropdown active options
  // This also control the open status
  var _React$useState = React.useState([]),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    activeValueCells = _React$useState2[0],
    setActiveValueCells = _React$useState2[1];
  React.useEffect(function () {
    if (!multiple) {
      setActiveValueCells(firstValueCells || []);
    }
  }, /* eslint-disable react-hooks/exhaustive-deps */
  [open, firstValueCells]
  /* eslint-enable react-hooks/exhaustive-deps */);

  return [activeValueCells, setActiveValueCells];
};
export default useActive;